import React, { useState } from "react";
import { navigate } from "gatsby";
import Layout from "../components/Layout/Layout";
import useIntervalWithHooks from "../components/Utils/useIntervalWithHooks";

export default () => {
  const [sec, setSec] = useState(8);

  useIntervalWithHooks(() => {
    if (sec > 0) {
      return setSec(sec - 1);
    }
    return navigate("/");
  }, 1000);

  return (
    <Layout>
      <div className="d-flex justify-content-center bg-sand">
        <div className="px-3 py-5 text-center">
          <h1 className="mb-3">404</h1>
          <img
            width={500}
            height={500}
            className="img-fluid mb-3"
            src="https://cms2.climacell.co/wp-content/uploads/2019/11/404_error.gif"
            alt="404. Page not found"
          />
          <p className="mb-1">
            We didn't find a page here. Cole tried really hard, though!
          </p>
          <p className="text-muted">
            You will be redirected to our homepage in {sec} sec
          </p>
        </div>
      </div>
    </Layout>
  );
};
